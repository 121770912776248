<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8008 7.5C10.9801 7.5 11.1457 7.40396 11.2348 7.2483C11.3238 7.09265 11.3227 6.90122 11.2318 6.74662L9.87366 4.43619C9.87345 4.43582 9.87323 4.43545 9.87302 4.43508C9.48933 3.77607 8.78104 3.375 8.02344 3.375H7.9375C6.79808 3.375 5.875 4.29808 5.875 5.4375C5.875 6.57692 6.79808 7.5 7.9375 7.5H10.8008ZM4.875 7.5C5.04888 7.5 5.21027 7.40967 5.3012 7.26145C5.39212 7.11324 5.39951 6.92843 5.32072 6.77343C5.11669 6.37206 5 5.91816 5 5.4375C5 3.81521 6.31521 2.5 7.9375 2.5H8.02344C9.09238 2.5 10.0845 3.06638 10.6274 3.99138L10.6275 3.99165L11.569 5.59322C11.6588 5.7461 11.8229 5.83994 12.0003 5.83984C12.1776 5.83975 12.3416 5.74573 12.4313 5.59276L13.3722 3.98816C13.3723 3.98805 13.3723 3.98793 13.3724 3.98782C13.9153 3.06648 14.9075 2.5 15.9766 2.5H16.0625C17.6848 2.5 19 3.81521 19 5.4375C19 5.91816 18.8833 6.37206 18.6793 6.77343C18.6005 6.92843 18.6079 7.11324 18.6988 7.26145C18.7897 7.40967 18.9511 7.5 19.125 7.5H20.75C21.1653 7.5 21.5 7.83474 21.5 8.25V10.75C21.5 11.1653 21.1653 11.5 20.75 11.5H3.25C2.83474 11.5 2.5 11.1653 2.5 10.75V8.25C2.5 7.83474 2.83474 7.5 3.25 7.5H4.875ZM12.7682 6.74662C12.6773 6.90122 12.6762 7.09265 12.7652 7.2483C12.8543 7.40396 13.0199 7.5 13.1992 7.5H16.0625C17.2019 7.5 18.125 6.57692 18.125 5.4375C18.125 4.29808 17.2019 3.375 16.0625 3.375H15.9766C15.2167 3.375 14.5142 3.77792 14.1278 4.4337L14.1276 4.43412L12.7682 6.74662ZM3.75 20.125V14.3182H10.5909V21.5H5.125C4.36599 21.5 3.75 20.884 3.75 20.125ZM18.875 21.5H13.4111L13.4394 14.3182H20.25V20.125C20.25 20.884 19.634 21.5 18.875 21.5Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 20.9985V11.033M7.68475 7.24806C5.93548 7.24806 4.60557 3 8.25807 3C10.5044 3 12 5.97379 12 7.24806C12 5.97379 13.4956 3 15.7419 3C19.3944 3 18.0645 7.24806 16.3152 7.24806M19.7361 19.5437V11.0359H4.26393V19.5437C4.26393 19.9299 4.41841 20.3003 4.69339 20.5735C4.96837 20.8466 5.34133 21 5.73021 21H18.2698C18.6587 21 19.0316 20.8466 19.3066 20.5735C19.5816 20.3003 19.7361 19.9299 19.7361 19.5437ZM21 8.70437V9.57816C21 9.96439 20.8455 10.3348 20.5705 10.6079C20.2956 10.881 19.9226 11.0345 19.5337 11.0345H4.46628C4.0774 11.0345 3.70444 10.881 3.42946 10.6079C3.15448 10.3348 3 9.96439 3 9.57816V8.70437C3 8.31813 3.15448 7.94771 3.42946 7.6746C3.70444 7.40149 4.0774 7.24806 4.46628 7.24806H19.5337C19.9226 7.24806 20.2956 7.40149 20.5705 7.6746C20.8455 7.94771 21 8.31813 21 8.70437Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
